import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';

// Shadcn Imports
import { Button } from "../components/ui/button";
import { useToast } from "../components/ui/use-toast";

// Lucide Icons
import { Check, Shield, ChevronLeft } from 'lucide-react';

// Environment variables
const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_API_URL;

interface PricingTierProps {
  title: string;
  price: number;
  subheader: string;
  features: string[];
  popular: boolean;
  onChoose: () => void;
}

const PricingTier: React.FC<PricingTierProps> = ({ title, price, subheader, features, popular, onChoose }) => (
  <div className={`bg-white rounded-2xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 ${popular ? 'border-2 border-blue-500 relative' : ''}`}>
    {popular && (
      <div className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 rounded-bl-lg text-sm font-semibold">
        Most Popular
      </div>
    )}
    <div className="p-8">
      <h3 className="text-2xl font-bold text-gray-900 mb-2">{title}</h3>
      <p className="text-sm text-gray-500 mb-4">{subheader}</p>
      <div className="flex items-baseline mb-6">
        <span className="text-5xl font-extrabold text-gray-900">${price}</span>
      </div>
      <ul className="space-y-4 mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <Check className="h-5 w-5 text-green-500 mr-2" />
            <span className="text-gray-700">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className="px-8 pb-8">
      <Button 
        onClick={onChoose} 
        className="w-full bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-300 py-4 rounded-xl flex items-center justify-center text-lg font-semibold shadow-lg hover:shadow-xl"
      >
        Choose Plan
      </Button>
    </div>
  </div>
);

const Payment = () => {
    const { toast } = useToast();
    const navigate = useNavigate();
    const checkoutUrl = `${API_URL}/api/create-checkout-session`;
    const token = localStorage.getItem('token');
  
    const handleLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      navigate('/signin');
    };

    const handlePayment = async (planType) => {
      try {
        const response = await fetch(checkoutUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ planType })
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Server responded with ${response.status}: ${errorText}`);
        }
  
        const { sessionId } = await response.json();
        const stripePromise = loadStripe(process.env.NODE_ENV === 'production'
          ? process.env.STRIPE_PUBLIC_KEY_LIVE
          : process.env.STRIPE_PUBLIC_KEY_TEST
        );
        const stripe = await stripePromise;
  
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            toast({
              title: "Error",
              description: `Failed to start checkout process: ${error.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: "Error",
            description: "Failed to load Stripe. Please try again.",
            variant: "destructive",
          });
        }
      } catch (error) {
        console.error('Error in payment process:', error);
        toast({
          title: "Error",
          description: error instanceof Error ? `An unexpected error occurred: ${error.message}` : "An unknown error occurred",
          variant: "destructive",
        });
      }
    };
  
    const pricingTiers = [
      {
        title: "Student",
        price: 99,
        subheader: "Best for students and graduates",
        features: [
          "Entry-Level Connections",
          "20 Emails Per Day Maximum",
          "Real-Time Analytics",
          "Verified Email Addresses",
          "Unlimited Email Scheduling",
        ],
        popular: false,
        planType: 'student'
      },
      {
        title: "Professional",
        price: 199,
        subheader: "Best for early professionals",
        features: [
          "Professional Connections",
          "30 Emails Per Day Maximum",
          "Real-Time Analytics",
          "Verified Email Addresses",
          "Unlimited Email Scheduling",
        ],
        popular: true,
        planType: 'professional'
      },
      {
        title: "Executive",
        price: 399,
        subheader: "Best for Directors, VPs, and C-Suite Executives",
        features: [
          "Premium Connections",
          "50 Emails Per Day Maximum",
          "Real-Time Analytics",
          "Verified Email Addresses",
          "Unlimited Email Scheduling",
        ],
        popular: false,
        planType: 'executive'
      }
    ];
  
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex flex-col items-center justify-center p-4">
          <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-start mb-8">
                  <Button
                      variant="ghost"
                      onClick={handleLogout}
                      className="flex items-center text-gray-600 hover:text-gray-900"
                  >
                      <ChevronLeft className="mr-2 h-4 w-4" />
                      Back
                  </Button>
              </div>
              <div className="text-center mb-16">
                  <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                      Choose Your Plan
                  </h2>
                  <p className="mt-5 text-xl text-gray-500">
                      Unlock Lance's Job Finder for 1 year - no subscription required
                  </p>
              </div>
              <div className="grid md:grid-cols-3 gap-8">
                  {pricingTiers.map((tier, index) => (
                      <PricingTier
                          key={index}
                          title={tier.title}
                          price={tier.price}
                          subheader={tier.subheader}
                          features={tier.features}
                          popular={tier.popular}
                          onChoose={() => handlePayment(tier.planType)}
                      />
                  ))}
              </div>
              <div className="mt-16 text-center">
                  <p className="text-base text-gray-500">
                      All plans include a 30-day money-back guarantee. Prices are in USD.
                  </p>
                  <div className="mt-4 flex justify-center items-center text-sm text-gray-600">
                      <Shield className="w-5 h-5 mr-2 text-green-500" />
                      Secure payment powered by Stripe
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Payment;
