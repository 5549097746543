import React from 'react';

const DottedBackground = ({ 
  dotColor = 'rgba(0, 0, 0, 0.1)', 
  backgroundColor = 'transparent',
  dotSize = 1,
  dotSpacing = 20,
  style = {}
}) => {
  const backgroundImage = `radial-gradient(${dotColor} ${dotSize}px, ${backgroundColor} ${dotSize}px)`;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage,
        backgroundSize: `${dotSpacing}px ${dotSpacing}px`,
        ...style
      }}
    />
  );
};

export default DottedBackground;