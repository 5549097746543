import React, { useState, useEffect, useRef } from 'react';
import { useToast } from "../components/ui/use-toast";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { Check } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

interface SettingsProps {
  userId: string | null;
}

const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_API_URL;
const STRIPE_PUBLIC_KEY = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST : process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE;

const Settings: React.FC<SettingsProps> = ({ userId }) => {
  const { toast } = useToast();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [userCredits, setUserCredits] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get('tab') || 'profile';

  const commonFeatures = [
    "CSV File Upload",
    "Email Validation",
    "Bulk Processing",
    "Syntax, MX, disposable, & role-based checks",
    "Downloadable Results"
  ];

  const tiers = [
    {
      title: "10,000 Emails",
      price: 9,
      subheader: "For small list cleaning and personal use",
      features: commonFeatures,
      package: "one",
    },
    {
      title: "50,000 Emails",
      price: 39,
      subheader: "For growing businesses and agencies",
      features: commonFeatures,
      package: "two",
      popular: true,
    },
    {
      title: "250,000 Emails",
      price: 99,
      subheader: "For large corporations with large lists",
      features: commonFeatures,
      package: "three",
    }
  ];

  useEffect(() => {
    const fetchUserSettings = async () => {
      if (!userId) return;

      try {
        const response = await fetch(`${API_URL}/api/user-settings/${userId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setEmail(data.email);
          setName(data.name);
          if (data.profileImageUrl) {
            console.log('Setting profile image URL from server:', data.profileImageUrl);
            setProfileImageUrl(data.profileImageUrl);
          }
        } else {
          throw new Error('Failed to fetch user settings');
        }
      } catch (error) {
        console.error('Error fetching user settings:', error);
        toast({
          title: "Error",
          description: "Failed to fetch user settings. Please try again.",
          variant: "destructive",
        });
      }
    };

    const fetchUserCredits = async () => {
      if (!userId) return;

      try {
        const response = await fetch(`${API_URL}/api/user-credits`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setUserCredits(data.credits);
        } else {
          throw new Error('Failed to fetch user credits');
        }
      } catch (error) {
        console.error('Error fetching user credits:', error);
        toast({
          title: "Error",
          description: "Failed to fetch user credits. Please try again.",
          variant: "destructive",
        });
      }
    };

    fetchUserSettings();
    fetchUserCredits();
  }, [userId, toast]);

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('File input change event triggered');
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      console.log('File selected:', file.name);
      setProfileImage(file);
      
      // Create a temporary URL for the selected image
      const tempUrl = URL.createObjectURL(file);
      setProfileImageUrl(tempUrl);
      
      console.log('Temporary profile image URL set:', tempUrl);
    } else {
      console.log('No file selected');
    }
  };

  const handleChangeClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpdateProfile = async () => {
    console.log('handleUpdateProfile called');
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      if (profileImage) {
        console.log('Appending profile image to formData:', profileImage.name);
        formData.append('profileImage', profileImage);
      } else {
        console.log('No profile image to append');
      }

      console.log('Sending update profile request');
      const response = await fetch(`${API_URL}/api/user-settings/${userId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
      });

      console.log('Response status:', response.status);
      console.log('Response ok:', response.ok);

      if (response.ok) {
        const result = await response.json();
        console.log('Update successful, result:', result);
        
        if (result.profileImageUrl) {
          console.log('Setting new profile image URL from server:', result.profileImageUrl);
          setProfileImageUrl(result.profileImageUrl);
        }
        
        toast({
          title: "Success",
          description: "Profile updated successfully.",
        });
      } else {
        const errorText = await response.text();
        console.error('Update failed, server response:', errorText);
        throw new Error(`Failed to update profile: ${errorText}`);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: "Error",
        description: "Failed to update profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "New passwords do not match.",
        variant: "destructive",
      });
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/change-password/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      });

      if (response.ok) {
        toast({
          title: "Success",
          description: "Password changed successfully.",
        });
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        throw new Error('Failed to change password');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      toast({
        title: "Error",
        description: "Failed to change password. Please check your current password and try again.",
        variant: "destructive",
      });
    }
  };

  const handleBuyCredits = async (creditPackage: string) => {
    try {
      const response = await fetch(`${API_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ creditPackage }),
      });
  
      if (response.ok) {
        const { sessionId } = await response.json();
        // Redirect to Stripe Checkout
        const stripe = await (window as any).Stripe(STRIPE_PUBLIC_KEY);
        await stripe.redirectToCheckout({ sessionId });
      } else {
        throw new Error('Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        title: "Error",
        description: "Failed to initiate purchase. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold">Settings</h1>
      
      <Tabs defaultValue={initialTab} className="space-y-6">
        <TabsList className="bg-secondary p-1 rounded-lg">
          <TabsTrigger value="profile" className="rounded-md transition-all duration-200">Profile</TabsTrigger>
          <TabsTrigger value="security" className="rounded-md transition-all duration-200">Security</TabsTrigger>
          <TabsTrigger value="billing" className="rounded-md transition-all duration-200">Billing</TabsTrigger>
        </TabsList>
  
        <TabsContent value="profile">
          <Card>
            <CardHeader>
              <CardTitle className="text-2xl">Profile Information</CardTitle>
              <CardDescription>Update your profile details here.</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center space-x-4">
                <Avatar className="h-16 w-16">
                  <AvatarImage src={profileImageUrl} alt={name} />
                  <AvatarFallback>{name ? name.charAt(0).toUpperCase() : 'U'}</AvatarFallback>
                </Avatar>
                <input
                  type="file"
                  onChange={handleProfileImageChange}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="profile-image-input"
                  ref={fileInputRef}
                />
                <Button
                  variant="outline"
                  size="sm"
                  className="space-x-1 transition-all duration-300 hover:bg-gray-200 hover:text-gray-800 cursor-pointer"
                  onClick={handleChangeClick}
                >
                  Change
                </Button>
              </div>
              <div className="space-y-2">
                <Label htmlFor="name" className="text-sm font-medium flex items-center">
                  Full Name
                </Label>
                <Input 
                  id="name" 
                  value={name} 
                  onChange={(e) => setName(e.target.value)} 
                  className="bg-background max-w-sm"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email" className="text-sm font-medium flex items-center">
                  Email
                </Label>
                <Input 
                  id="email" 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  className="bg-background max-w-sm"
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button onClick={handleUpdateProfile} className="w-full sm:w-auto">
                Save Changes
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>
  
        <TabsContent value="security">
          <Card>
            <CardHeader>
              <CardTitle className="text-2xl">Change Password</CardTitle>
              <CardDescription>Ensure your account is using a strong, unique password to stay secure.</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="current-password" className="text-sm font-medium flex items-center">
                  Current Password
                </Label>
                <Input
                  id="current-password"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="bg-background max-w-sm"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="new-password" className="text-sm font-medium">New Password</Label>
                <Input
                  id="new-password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="bg-background max-w-sm"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="confirm-password" className="text-sm font-medium">Confirm New Password</Label>
                <Input
                  id="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="bg-background max-w-sm"
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button onClick={handleChangePassword} className="w-full sm:w-auto">
                Change Password
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>
  
        <TabsContent value="billing">
          <Card>
            <CardHeader>
            <CardTitle className="text-2xl">Billing</CardTitle>
            <CardDescription>Manage your credits and purchase more</CardDescription>
            </CardHeader>
            <CardContent className="space-y-8">
              <div className="bg-secondary p-6 rounded-lg text-center">
                <h3 className="text-xl font-semibold mb-2">Current Credits</h3>
                <p className="text-5xl font-bold text-primary">{userCredits}</p>
                <p className="text-sm text-muted-foreground mt-2">Available email validation credits</p>
              </div>

              <div>
                <h2 className="text-2xl font-bold tracking-tight mb-4">
                  Purchase Additional Credits
                </h2>
                <p className="text-muted-foreground mb-6">
                  Choose a package that fits your needs. No hidden fees.
                </p>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {tiers.map((tier, index) => (
                    <Card key={index} className={`overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1 ${tier.popular ? 'border-2 border-primary relative' : ''}`}>
                      {tier.popular && (
                        <div className="absolute top-0 right-0 bg-primary text-primary-foreground px-4 py-1 text-xs font-semibold">
                          Most Popular
                        </div>
                      )}
                      <CardContent className="p-4">
                        <h3 className="text-xl font-bold mb-2">{tier.title}</h3>
                        <p className="text-sm text-muted-foreground mb-3">{tier.subheader}</p>
                        <div className="flex items-baseline mb-4">
                          <span className="text-3xl font-bold">${tier.price}</span>
                        </div>
                        <ul className="space-y-2 mb-4">
                          {tier.features.map((feature, featureIndex) => (
                            <li key={featureIndex} className="flex items-center">
                              <Check className="h-4 w-4 text-primary mr-2 flex-shrink-0" />
                              <span className="text-muted-foreground text-xs">{feature}</span>
                            </li>
                          ))}
                        </ul>
                        <Button 
                          className="w-full" 
                          onClick={() => handleBuyCredits(tier.package)}
                        >
                          Buy Now
                        </Button>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Settings;