import React, { useState, useEffect } from 'react';
import { useToast } from "../components/ui/use-toast";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import { Progress } from "../components/ui/progress";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { motion, AnimatePresence } from 'framer-motion';
import { UploadCloud, Download, RefreshCw, CheckCircle, XCircle, AlertTriangle, ClockIcon, ChevronLeft, ChevronRight } from 'lucide-react';

const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_API_URL;

const BounceTester = () => {
  const { toast } = useToast();
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [columns, setColumns] = useState([]);
  const [emailColumn, setEmailColumn] = useState('');
  const [error, setError] = useState(null);
  const [pastRuns, setPastRuns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const runsPerPage = 10;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchPastRuns();
  }, []);

  const fetchPastRuns = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/bounce-test-runs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const runs = await response.json();
        setPastRuns(runs);
      } else {
        console.error('Failed to fetch past runs');
      }
    } catch (error) {
      console.error('Error fetching past runs:', error);
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      try {
        const headers = await getCSVHeaders(selectedFile);
        setColumns(headers);
        setEmailColumn(headers[0] || '');
        setError(null);
      } catch (error) {
        setError("Failed to read CSV headers. Please check the file format.");
        toast({
          title: "Error",
          description: "Failed to read CSV headers. Please check the file format.",
          variant: "destructive",
        });
      }
    } else {
      setError("Invalid file type. Please upload a CSV file.");
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file.",
        variant: "destructive",
      });
    }
  };

  const handleDeleteRun = async (runId: string) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/bounce-test-run/${runId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.ok) {
        toast({
          title: "Run deleted",
          description: "The run has been successfully deleted.",
        });
        fetchPastRuns(); // Refresh the list of runs
      } else {
        throw new Error('Failed to delete run');
      }
    } catch (error) {
      console.error('Error deleting run:', error);
      toast({
        title: "Error",
        description: "Failed to delete the run. Please try again.",
        variant: "destructive",
      });
    }
  };

  const getCSVHeaders = (file): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = e.target.result;
          const firstLine = (content as string).split('\n')[0];
          const headers = firstLine.split(',').map(header => header.trim());
          resolve(headers);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const handleUpload = async () => {
    if (!file) {
      setError("No file selected. Please select a CSV file to upload.");
      toast({
        title: "No file selected",
        description: "Please select a CSV file to upload.",
        variant: "destructive",
      });
      return;
    }
  
    if (!emailColumn) {
      setError("Email column not selected. Please select the column containing email addresses.");
      toast({
        title: "Email column not selected",
        description: "Please select the column containing email addresses.",
        variant: "destructive",
      });
      return;
    }
  
    setIsProcessing(true);
    setError(null);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('emailColumn', emailColumn);
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/clean-email-list`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Server responded with an error');
      }
  
      const result = await response.json();
      toast({
        title: "Processing started",
        description: `Processing ${result.totalEmails} emails. You can check the results later.`,
      });
    } catch (error) {
      console.error('Error starting email list processing:', error);
      setError("Failed to start processing the email list. Please try again.");
      toast({
        title: "Error",
        description: "Failed to start processing the email list. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
      fetchPastRuns(); // Refresh the list of past runs
    }
  };

  const handleDownload = (type, runId) => {
    const run = pastRuns.find(r => r._id === runId);
    if (!run || !run.results) {
      toast({
        title: "Error",
        description: "Run data not found.",
        variant: "destructive",
      });
      return;
    }
  
    const emails = type === 'valid' ? run.results.validEmails : run.results.invalidEmails;
    if (!emails || emails.length === 0) {
      toast({
        title: "No Data",
        description: `No ${type} emails found for this run.`,
        variant: "default",
      });
      return;
    }
  
    const csvContent = [
      Object.keys(emails[0]),
      ...emails.map(item => Object.values(item))
    ].map(e => e.join(",")).join("\n");
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${type}_emails_${runId}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const indexOfLastRun = currentPage * runsPerPage;
  const indexOfFirstRun = indexOfLastRun - runsPerPage;
  const currentRuns = pastRuns.slice(indexOfFirstRun, indexOfLastRun);
  const totalPages = Math.ceil(pastRuns.length / runsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold">Email List Cleaner</h1>
      
      <Card>
        <CardHeader>
          <CardTitle>Upload CSV File</CardTitle>
          <CardDescription>Upload a CSV file containing email addresses to clean</CardDescription>
        </CardHeader>
        <CardContent>
          <AnimatePresence>
            {!file && !isProcessing && (
              <motion.div
                className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center"
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.3 }}
              >
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer flex flex-col items-center space-y-2"
                >
                  <UploadCloud className="w-16 h-16 text-primary" />
                  <p className="text-xl">
                    <span className="text-primary font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-sm text-muted-foreground">CSV file (MAX. 5000 emails)</p>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".csv"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </motion.div>
            )}
          </AnimatePresence>
          
          {file && !isProcessing && (
            <motion.div
              className="space-y-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <p className="text-lg">
                Selected file: <span className="font-semibold">{file.name}</span>
              </p>
              <div className="max-w-xs">
                <Select value={emailColumn} onValueChange={setEmailColumn}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select email column" />
                  </SelectTrigger>
                  <SelectContent>
                    {columns.map((column) => (
                      <SelectItem key={column} value={column}>
                        {column}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Button 
                onClick={handleUpload}
                className="w-full max-w-xs"
              >
                Clean Email List
              </Button>
            </motion.div>
          )}
          
          {isProcessing && (
            <motion.div 
              className="space-y-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <RefreshCw className="w-16 h-16 text-primary animate-spin mx-auto" />
              <h3 className="text-xl font-semibold text-center">Processing your list</h3>
              <p className="text-muted-foreground text-center">This may take a few moments...</p>
              <Progress value={progress} className="w-full" />
              <p className="text-lg font-semibold text-center">Progress: {progress.toFixed(0)}%</p>
            </motion.div>
          )}

          {error && (
            <motion.div
              className="mt-4 p-4 bg-destructive/10 border border-destructive text-destructive rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <p className="font-bold">Error:</p>
              <p>{error}</p>
            </motion.div>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Past Runs</CardTitle>
          <CardDescription>View and download results from previous email list cleanings</CardDescription>
        </CardHeader>
        <CardContent>
          {pastRuns.length === 0 ? (
            <p className="text-center text-muted-foreground">No past runs available</p>
          ) : (
            <>
              <ul className="space-y-4">
                {currentRuns.map((run, index) => (
                  <li key={run._id} className="border-b pb-4 last:border-b-0">
                    <div className="flex flex-col space-y-2">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-lg font-semibold">{run.results.fileName || `Run #${pastRuns.length - (indexOfFirstRun + index)}`}</p>
                          <p className="text-sm text-muted-foreground">
                            <ClockIcon className="inline-block mr-1 w-4 h-4" />
                            {new Date(run.timestamp).toLocaleDateString()}
                          </p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="text-sm text-muted-foreground">Run #{pastRuns.length - (indexOfFirstRun + index)}</div>
                          <Button
                            onClick={() => handleDeleteRun(run._id)}
                            variant="ghost"
                            size="sm"
                            className="text-destructive hover:bg-destructive/10"
                          >
                            <XCircle className="w-4 h-4" />
                          </Button>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex space-x-4">
                          <p className="text-sm">
                            <CheckCircle className="inline-block mr-1 w-4 h-4 text-green-500" />
                            Valid: {run.results.validCount}
                          </p>
                          <p className="text-sm">
                            <XCircle className="inline-block mr-1 w-4 h-4 text-destructive" />
                            Invalid: {run.results.invalidCount}
                          </p>
                        </div>
                        <div className="flex space-x-2">
                          <DownloadButton onClick={() => handleDownload('valid', run._id)} label="Valid" />
                          <DownloadButton onClick={() => handleDownload('invalid', run._id)} label="Invalid" />
                        </div>
                      </div>
                    </div>
                  </li>                
                ))}
              </ul>
              <div className="flex justify-center items-center space-x-2 mt-6">
                <Button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant="outline"
                  size="sm"
                >
                  <ChevronLeft className="w-4 h-4" />
                </Button>
                <span className="text-sm text-muted-foreground">
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  variant="outline"
                  size="sm"
                >
                  <ChevronRight className="w-4 h-4" />
                </Button>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const DownloadButton = ({ onClick, label }) => (
  <Button
    onClick={onClick}
    variant="outline"
    size="sm"
    className="space-x-1 transition-all duration-300 hover:bg-primary hover:text-primary-foreground"
  >
    <Download className="w-3 h-3" />
    <span>{label}</span>
  </Button>
);

export default BounceTester;