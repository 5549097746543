import React, { useState, useRef } from 'react';
import Link from 'next/link';
import { ArrowRight, Play } from 'lucide-react';
import { Button } from "../components/ui/button";
import DottedBackground from '../components/ui/dotted-background';
import { Card, CardContent } from "../components/ui/card";

const DemoVideo = require('../assets/videos/demo.mp4');
const Thumbnail = require('../assets/images/thumbnail.png');

const isProd = process.env.NODE_ENV === 'production';
const signUp = isProd ? 'https://app.lancesbouncetester.com/signup' : `${process.env.REACT_APP_DEV_URL}/signin`;

const EnhancedVideoCard = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <Card className="w-full max-w-5xl mx-auto overflow-hidden">
      <CardContent className="p-0">
        <div className="relative" style={{ paddingBottom: `${(435 / 836) * 100}%` }}> {/* 836:435 aspect ratio */}
          <video
            ref={videoRef}
            src={DemoVideo}
            className="absolute top-0 left-0 w-full h-full object-cover"
            poster={Thumbnail}
            controls={isPlaying}
          >
            Your browser does not support the video tag.
          </video>
          {!isPlaying && (
            <div 
              className="absolute inset-0 flex items-center justify-center bg-black/30 hover:bg-black/50 transition-colors duration-300"
              style={{
                backgroundImage: `url(${Thumbnail})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <Button 
                onClick={handlePlay}
                variant="secondary"
                size="lg"
                className="flex items-center space-x-2"
              >
                <Play className="w-4 h-4" />
                <span>Watch Demo</span>
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const HeroSection = () => {
  return (
    <header className="relative py-16 sm:py-24 flex flex-col justify-center items-center px-4 sm:px-6 overflow-hidden bg-gradient">
      <DottedBackground />
      <div className="container relative z-20 text-center mb-16">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight mb-6 leading-tight">
          Bulk Email Cleaning,<br className="hidden sm:inline" />
          90% Cheaper
        </h1>
        <p className="text-lg sm:text-xl mb-8 text-muted-foreground leading-relaxed max-w-2xl mx-auto">
          Clean your email lists with 95%+ accuracy without paying ridiculous prices. Improve deliverability and campaign effectiveness - all for a fraction of the cost of enterprise alternatives.
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
          <Button asChild>
            <Link href={signUp}>
              Get started
              <ArrowRight className="ml-2 w-4 h-4" />
            </Link>
          </Button>
        </div>
      </div>
      
      <div className="w-full max-w-6xl mx-auto relative z-20" id="hero">
        <EnhancedVideoCard />
      </div>
    </header>
  );
};

export default HeroSection;