import React, { useEffect, useCallback } from 'react';
import Navbar from '../components/navbar';
import HeroSection from '../components/hero';
import StatsSection from '../components/stats';
import PricingSection from '../components/pricing';
import FAQSection from '../components/faq';
import CallToActionSection from '../components/call-to-action';
import FeaturesSection from '../components/features';
import BioSection from '../components/bio';
import Footer from '../components/footer';

const LandingPage = () => {
  const scrollTo = useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      const navbar = document.querySelector('nav');
      const navbarHeight = navbar ? navbar.offsetHeight : 0;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setTimeout(() => {
        if (Math.abs(window.pageYOffset - offsetPosition) > 1) {
          window.scrollTo(0, offsetPosition);
        }
      }, 500);
    } else {
      setTimeout(() => {
        const delayedElement = document.getElementById(id);
        if (delayedElement) {
          scrollTo(id);
        }
      }, 100);
    }
  }, []);
  
  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.substring(1);
      scrollTo(id);
    }
  }, [scrollTo]);

  return (
    <div className="min-h-screen bg-white text-gray-900">
      <Navbar scrollTo={scrollTo} />

      <HeroSection />

      <StatsSection />

      <FeaturesSection />

      <PricingSection />

      <FAQSection />

      <BioSection />

      <CallToActionSection />

      <Footer />
    </div>
  );
};

export default LandingPage;