import React from 'react';
import { Link } from 'react-router-dom';
import { Rabbit, Twitter, Linkedin, Github, ExternalLink } from 'lucide-react';
import DottedBackground from '../components/ui/dotted-background';

const Footer: React.FC = () => {
  return (
    <footer className="bg-background p-4 relative overflow-hidden">
      <DottedBackground />
      <div className="w-full mx-auto relative z-10">
        <div className="bg-primary rounded-3xl shadow-lg">
          <div className="max-w-6xl mx-auto px-6 sm:px-8 lg:px-12 py-10 lg:py-16">
            <div className="flex flex-col md:flex-row justify-between">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <div className="flex items-center mb-4">
                  <Rabbit className="h-8 w-8 text-primary-foreground mr-2" />
                  <span className="text-xl font-semibold text-primary-foreground">Lance's Bounce Tester</span>
                </div>
                <p className="text-primary-foreground/80 mb-6">Stop wasting money on bounce testing. Save 90%.</p>
                <div className="flex space-x-4">
                  <a href="https://x.com/lance_bricca" className="text-primary-foreground hover:text-primary-foreground/80 transition-colors">
                    <Twitter size={20} />
                  </a>
                  <a href="https://www.linkedin.com/in/lance-bricca/" className="text-primary-foreground hover:text-primary-foreground/80 transition-colors">
                    <Linkedin size={20} />
                  </a>
                  <a href="https://github.com/lancembricca" className="text-primary-foreground hover:text-primary-foreground/80 transition-colors">
                    <Github size={20} />
                  </a>
                </div>
              </div>
              
              <div className="md:w-1/4 md:text-right">
                <h3 className="font-semibold mb-3 text-primary-foreground">Other Products</h3>
                <ul className="space-y-2 text-sm">
                  <li>
                    <a 
                      href="https://www.lancesjobfinder.com" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-primary-foreground/80 hover:text-primary-foreground transition-colors inline-flex items-center"
                    >
                      Lance's Job Finder
                      <ExternalLink size={14} className="ml-1" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="mt-10 pt-8 border-t border-primary-foreground/20">
              <div className="flex flex-col md:flex-row justify-between items-center">
                <p className="text-primary-foreground/80 text-sm">&copy; {new Date().getFullYear()} Lance Bricca. All rights reserved.</p>
                <div className="flex space-x-4 mt-4 md:mt-0">
                  <Link to="/privacy-policy" className="text-primary-foreground/80 hover:text-primary-foreground transition-colors text-sm">Privacy Policy</Link>
                  <Link to="/terms-of-service" className="text-primary-foreground/80 hover:text-primary-foreground transition-colors text-sm">Terms of Service</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;