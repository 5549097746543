import React from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion";
import DottedBackground from '../components/ui/dotted-background';

const FAQSection = () => {
  const faqs = [
    {
      question: "How does Lance's Bounce Tester work?",
      answer: "The bounce tester uses advanced algorithms to validate email addresses, remove duplicates, and identify potential issues like catch-all domains. It checks the format, domain existence, and mailbox availability to ensure your list contains only valid, active email addresses."
    },
    {
      question: "How long does it take to clean my email list?",
      answer: "The cleaning process is typically very fast. Most lists are processed within minutes, though larger lists may take a bit longer. You'll see real-time progress as your list is being cleaned."
    },
    {
      question: "Can I use Lance's Bounce Tester with my existing email marketing software?",
      answer: "Yes, Lance's Bounce Tester is compatible with all major email marketing platforms. You can easily export your cleaned list and import it into your preferred email marketing software."
    },
    {
      question: "What types of validation does the bounce tester perform?",
      answer: "We perform syntax checks, domain validation, MX record verification, and mailbox verification where possible. We also identify role-based emails, disposable email addresses, and potential typos."
    },
    {
      question: "Is my data safe and secure?",
      answer: "Absolutely. We take data security very seriously. All data is encrypted in transit and at rest. We never share or sell your data, and we delete your lists from our servers once the cleaning process is complete."
    }
  ];

  return (
    <section className="py-16 sm:py-24 bg-background relative overflow-hidden" id="faq">
      <div className="absolute inset-0 z-0">
        <DottedBackground />
      </div>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <h2 className="text-4xl sm:text-5xl font-bold text-left text-foreground mb-4">Got Questions? I've got some answers!</h2>
        <p className="text-left text-lg mb-12">
          If you have any other questions, feel free to reach out to me at{' '}
          <a href="mailto:lance@lancebricca.com" className="text-primary hover:underline">lance@lancebricca.com</a>
          . I'm happy to help!
        </p>
        <Accordion type="single" collapsible className="space-y-4">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`} className="bg-white border border-border rounded-lg overflow-hidden shadow-sm">
              <AccordionTrigger className="flex justify-between items-center w-full text-left text-lg font-medium px-6 py-4 hover:bg-accent/10 transition-colors duration-300">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="text-muted-foreground px-6 py-4">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default FAQSection;