import React, { useEffect, useState } from 'react';
import { Rabbit, Menu, X } from 'lucide-react';
import { Button } from "../components/ui/button";

const isProd = process.env.NODE_ENV === 'production';
const baseUrl = isProd ? process.env.REACT_APP_URL : process.env.REACT_APP_DEV_URL;
const signIn = isProd ? 'https://app.lancesbouncetester.com/signin' : `${process.env.REACT_APP_DEV_URL}/signin`;
const signUp = isProd ? 'https://app.lancesbouncetester.com/signup' : `${process.env.REACT_APP_DEV_URL}/signin`;

const Navbar = ({ scrollTo }) => {
  const [isHomePage, setIsHomePage] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setIsHomePage(currentPath === '/');
  }, []);

  const handleNavigation = (sectionId) => {
    if (isHomePage && typeof scrollTo === 'function') {
      scrollTo(sectionId);
    } else {
      window.location.href = `${baseUrl}/#${sectionId}`;
    }
    setIsMenuOpen(false);
  };

  return (
    <nav className="fixed w-full bg-background/80 backdrop-blur-md shadow-sm z-50">
      <div className="container mx-auto">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <a href={baseUrl} className="flex items-center">
              <Rabbit className="h-8 w-8 text-primary" />
              <span className="ml-2 text-xl font-semibold text-foreground">Lance's Bounce Tester</span>
            </a>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <Button variant="ghost" className="text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('hero')}>Demo</Button>
            <Button variant="ghost" className="text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('pricing')}>Pricing</Button>
            <Button variant="ghost" className="text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('about')}>About</Button>
            <Button variant="ghost" className="text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('faq')}>FAQs</Button>
            <Button variant="outline" asChild>
              <a href={signIn}>Sign in</a>
            </Button>
            <Button asChild>
              <a href={signUp}>Get started</a>
            </Button>
          </div>
          <div className="md:hidden">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </Button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-background">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Button variant="ghost" className="w-full justify-start text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('hero')}>Demo</Button>
            <Button variant="ghost" className="w-full justify-start text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('pricing')}>Pricing</Button>
            <Button variant="ghost" className="w-full justify-start text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('about')}>About</Button>
            <Button variant="ghost" className="w-full justify-start text-gray-600 hover:text-gray-900" onClick={() => handleNavigation('faq')}>FAQs</Button>
          </div>
          <div className="pt-4 pb-3 border-t border-border">
            <div className="flex items-center px-5 space-x-2">
              <Button variant="outline" className="w-full" asChild>
                <a href={signIn}>Sign in</a>
              </Button>
              <Button className="w-full" asChild>
                <a href={signUp}>Get started</a>
              </Button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;