import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <Navbar scrollTo={undefined} />
      <div className="policy-content-wrapper">
        <main className="policy-main">
          <article className="policy-article">
            <h1 className="policy-title">Privacy Policy</h1>
            <p className="policy-date">Last Updated: August 30, 2024</p>

            <section className="policy-section">
              <h2 className="policy-section-title">1. Introduction</h2>
              <p className="policy-text">
                Welcome to Lance's Job Finder ("we," "our," or "us"). We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service.
              </p>
              <p className="policy-text">
                We provide an automated job search service that helps connect job seekers with potential employers, primarily targeting small to medium-sized companies. Our service is designed to streamline your job search process by automating email outreach to company executives and decision-makers.
              </p>
              <p className="policy-text">
                Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">2. Information We Collect</h2>
              <h3 className="policy-subsection-title">2.1 Personal Information You Disclose to Us</h3>
              <p className="policy-text">
                We collect personal information that you voluntarily provide to us when you register for Lance's Job Finder, express an interest in obtaining information about us or our products and services, or otherwise contact us. The personal information we collect may include:
              </p>
              <ul className="policy-list">
                <li>Email address</li>
                <li>Job preferences and career information</li>
                <li>Any other information you choose to provide</li>
              </ul>

              <h3 className="policy-subsection-title">2.2 Information Automatically Collected</h3>
              <p className="policy-text">
                As you navigate through and interact with our service, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
              </p>
              <ul className="policy-list">
                <li>Details of your visits to our website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the website</li>
                <li>Information about your computer and internet connection, including your IP address, operating system, and browser type</li>
              </ul>

              <h3 className="policy-subsection-title">2.3 Information Collected through Third-Party Sources</h3>
              <p className="policy-text">
                We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as from other third parties. This information may include:
              </p>
              <ul className="policy-list">
                <li>Social media profile information (your name, gender, birthday, email, current city, state and country, user identification numbers for your contacts, profile picture URL, and any other information that you choose to make public)</li>
                <li>Information from Google when you authenticate your Gmail account for our service</li>
              </ul>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">3. How We Use Your Information</h2>
              <p className="policy-text">
                We use personal information collected via our service for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We use the information we collect or receive:
              </p>
              <ul className="policy-list">
                <li>To facilitate account creation and logon process</li>
                <li>To automate your job search process</li>
                <li>To send customized emails to potential employers on your behalf</li>
                <li>To send you marketing and promotional communications</li>
                <li>To respond to your inquiries and solve any potential issues you might have with the use of our service</li>
                <li>To improve and optimize our service</li>
                <li>To protect our service, users, and others from fraud or illegal activity</li>
                <li>To comply with legal obligations</li>
              </ul>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">4. Gmail Integration</h2>
              <p className="policy-text">
                Lance's Job Finder integrates with your Gmail account to send emails on your behalf. We access your Gmail account through Google authentication. Our access is limited to sending emails and we do not read, store, or process any other information from your Gmail account.
              </p>
              <h3 className="policy-subsection-title">4.1 Scope of Access</h3>
              <p className="policy-text">
                When you grant us permission to access your Gmail account, we can:
              </p>
              <ul className="policy-list">
                <li>Send emails as you</li>
                <li>View your email address</li>
              </ul>
              <h3 className="policy-subsection-title">4.2 Data Usage</h3>
              <p className="policy-text">
                We use this access solely for the purpose of sending automated job application emails to potential employers. We do not:
              </p>
              <ul className="policy-list">
                <li>Read your emails</li>
                <li>Access your contacts</li>
                <li>Modify any settings in your Gmail account</li>
                <li>Use your email for any purpose other than sending job application emails</li>
              </ul>
              <h3 className="policy-subsection-title">4.3 Revoking Access</h3>
              <p className="policy-text">
                You can revoke our access to your Gmail account at any time through your Google Account settings. Once access is revoked, we will no longer be able to send emails on your behalf.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">5. Data Retention</h2>
              <p className="policy-text">
                We keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
              </p>
              <p className="policy-text">
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it. If this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">6. Data Sharing and Disclosure</h2>
              <p className="policy-text">
                We do not share or sell your personal information to third parties for their marketing purposes. However, we may disclose your personal information in the following situations:
              </p>
              <ul className="policy-list">
                <li>Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li>Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy.</li>
                <li>Business Partners: We may share your information with our business partners to offer you certain products, services or promotions.</li>
                <li>With Your Consent: We may disclose your personal information for any other purpose with your consent.</li>
                <li>Other Users: When you share personal information or otherwise interact with public areas of the service, such personal information may be viewed by all users and may be publicly distributed outside the service in perpetuity.</li>
              </ul>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">7. Security</h2>
              <p className="policy-text">
                We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our service is at your own risk. You should only access the services within a secure environment.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">8. International Data Transfers</h2>
              <p className="policy-text">
                Our service is globally accessible, which involves a transfer of data across international boundaries. We store and process your information in the United States and other countries. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to such transfers.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">9. Your Rights</h2>
              <p className="policy-text">
                Depending on your location, you may have certain rights regarding your personal information:
              </p>
              <ul className="policy-list">
                <li>The right to access, update or to delete the information we have on you</li>
                <li>The right of rectification</li>
                <li>The right to object</li>
                <li>The right of restriction</li>
                <li>The right to data portability</li>
                <li>The right to withdraw consent</li>
              </ul>
              <p className="policy-text">
                Currently, we do not offer an option for users to delete their account and associated data directly through our service. We are working on implementing this feature in the future. In the meantime, if you wish to exercise any of these rights, please contact us using the information provided in the "Contact Us" section.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">10. Age Restrictions</h2>
              <p className="policy-text">
                Our service is not directed to individuals under the age of 18, and we do not knowingly collect personal information from individuals under 18. If we learn that we have collected personal data from a person under 18, we will delete that data as quickly as possible. If you believe that we might have any information from or about a child under 18, please contact us.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">11. Specific Privacy Rights by Region</h2>
              <h3 className="policy-subsection-title">11.1 Privacy Notice for California Residents</h3>
              <p className="policy-text">
                If you are a resident of California, you are granted specific rights regarding access to your personal information. California Civil Code Section 1798.83 permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year.
              </p>
              <h3 className="policy-subsection-title">11.2 Privacy Notice for European Union Residents</h3>
              <p className="policy-text">
                If you are a resident of the European Union, you have certain data protection rights under the GDPR. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal information. If you wish to be informed what personal information we hold about you and if you want it to be removed from our systems, please contact us.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">12. Cookies and Tracking Technologies</h2>
              <p className="policy-text">
                We may use cookies, web beacons, tracking pixels, and other tracking technologies to help customize our service and improve your experience. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of our service.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">13. Controls for Do-Not-Track Features</h2>
              <p className="policy-text">
                Most web browsers and some mobile operating systems include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">14. Updates to This Privacy Policy</h2>
              <p className="policy-text">
                We may update this privacy policy from time to time. The updated version will be indicated by an updated "Last Updated" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">15. Contact Us</h2>
              <p className="policy-text">
                If you have questions or comments about this policy, you may contact us at: lance@lancesjobfinder.com
              </p>
            </section>
          </article>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;