import React from 'react';
import { Card, CardContent } from "../components/ui/card";
import DottedBackground from '../components/ui/dotted-background';
const Headshot = require('../assets/images/headshot.png');


const BioSection = () => {
  return (
    <section className="py-16 sm:py-24 relative overflow-hidden" id="about">
      <DottedBackground />

      <div className="container relative z-10">
        <div className="max-w-5xl mx-auto text-left">
          <h2 className="text-4xl sm:text-5xl font-bold tracking-tight mb-4">
            Who built this?
          </h2>

          <div className="w-full md:w-2/3">
            <p className="text-lg mb-8">
              Howdy, 👋 I'm Lance Bricca. 
            </p>
            <p className="text-lg mb-8">
              I'm a marketing and sales veteran turned full-stack developer, inspired by Claude Sonnet 3.5's
              launch in June 2024. This is my second full-stack app. My mission? Creating affordable alternatives
              to ridiculously priced SaaS tools and building solutions I've always wanted.
            </p>
          </div>

          <a href="https://www.lancebricca.com" target="_blank" rel="noopener noreferrer" className="block w-64 group">
            <Card className="transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
              <CardContent className="p-4 flex items-center relative">
                <div className="w-16 h-16 mr-4 overflow-hidden rounded-full">
                  <img
                    src={Headshot}
                    alt="Lance Bricca"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h3 className="font-semibold text-2xl mb-1">Lance Bricca</h3>
                  <p className="text-sm text-gray-500">lance@lancebricca.com</p>
                </div>
              </CardContent>
            </Card>
          </a>
        </div>
      </div>
    </section>
  );
};

export default BioSection;