import React from 'react';
import { Zap, CheckCircle, DollarSign, Clock, Shield } from 'lucide-react';
import DottedBackground from '../components/ui/dotted-background';

const StatsSection = () => {
  const stats = [
    {
      icon: Zap,
      title: "Processing Speed",
      value: "500+",
      description: "Emails validated per minute",
      color: "bg-yellow-100 text-yellow-600"
    },
    {
      icon: CheckCircle,
      title: "Accuracy Rate",
      value: "95%",
      description: "Precision in email validation",
      color: "bg-green-100 text-green-600"
    },
    {
      icon: DollarSign,
      title: "Cost Savings",
      value: "10x",
      description: "Cheaper than alternatives like NeverBounce",
      color: "bg-blue-100 text-blue-600"
    },
    {
      icon: Shield,
      title: "Advanced Checks",
      value: "7+ Methods",
      description: "For each email validation",
      color: "bg-purple-100 text-purple-600"
    }
  ];

  return (
    <section className="py-16 sm:py-24 bg-white relative overflow-hidden">
      <DottedBackground />
      <div className="container relative z-10">
        <div className="text-center mb-12 sm:mb-16">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-foreground">10x Cheaper and 95% as Efficient</h2>
          <p className="mt-4 text-lg sm:text-xl text-muted-foreground">Clean your bulk email lists without breaking the bank</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className={`p-6 rounded-lg shadow ${stat.color}`}>
              <div className="w-12 h-12 mb-4 rounded-full bg-white flex items-center justify-center">
                <stat.icon className="w-6 h-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">{stat.title}</h3>
              <div className="text-2xl sm:text-3xl font-bold mb-2">{stat.value}</div>
              <p className="text-sm opacity-80">{stat.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatsSection;