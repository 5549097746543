import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';

const TermsOfService = () => {
  return (
    <div className="policy-container">
      <Navbar scrollTo={undefined} />
      <div className="policy-content-wrapper">
        <main className="policy-main">
          <article className="policy-article">
            <h1 className="policy-title">Terms of Service</h1>
            <p className="policy-date">Last Updated: August 30, 2024</p>

            <p className="policy-text">
              Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the Lance's Job Finder service operated by [Your Company Name] ("us", "we", "our").
            </p>

            <section className="policy-section">
              <h2 className="policy-section-title">1. Acceptance of Terms</h2>
              <p className="policy-text">
                By accessing or using our service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the service.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">2. Description of Service</h2>
              <p className="policy-text">
                Lance's Job Finder is an automated job search service designed to help users find employment opportunities by sending customized emails to potential employers, primarily targeting small to medium-sized companies.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">3. Registration and Account</h2>
              <p className="policy-text">
                3.1. To use Lance's Job Finder, you must create an account and provide certain information about yourself.
              </p>
              <p className="policy-text">
                3.2. You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account.
              </p>
              <p className="policy-text">
                3.3. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">4. Gmail Integration</h2>
              <p className="policy-text">
                4.1. Our service requires access to your Gmail account to send emails on your behalf.
              </p>
              <p className="policy-text">
                4.2. By using our service, you authorize Lance's Job Finder to access your Gmail account solely for the purpose of sending job application emails.
              </p>
              <p className="policy-text">
                4.3. We do not read, store, or process any other information from your Gmail account beyond what is necessary to send emails.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">5. User Conduct</h2>
              <p className="policy-text">
                5.1. You agree to use Lance's Job Finder only for lawful purposes and in accordance with these Terms of Service.
              </p>
              <p className="policy-text">
                5.2. You agree not to use the service:
              </p>
              <ul className="policy-list">
                <li>In any way that violates any applicable national or international law or regulation.</li>
                <li>To impersonate or attempt to impersonate other individuals or entities.</li>
                <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the service.</li>
              </ul>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">6. Content</h2>
              <p className="policy-text">
                6.1. You are solely responsible for the content of the emails sent through our service, including your resume and any other information you provide.
              </p>
              <p className="policy-text">
                6.2. You grant us the right to use, modify, and transmit this content as necessary to provide our service.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">7. Intellectual Property</h2>
              <p className="policy-text">
                7.1. The service and its original content, features, and functionality are and will remain the exclusive property of Lance's Job Finder and its licensors.
              </p>
              <p className="policy-text">
                7.2. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Lance's Job Finder.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">8. Pricing and Payment</h2>
              <p className="policy-text">
                8.1. Lance's Job Finder is a paid service. Pricing information is available on our website.
              </p>
              <p className="policy-text">
                8.2. You agree to pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">9. 30-Day Money-Back Guarantee</h2>
              <p className="policy-text">
                9.1. We offer a 30-day money-back guarantee on our service.
              </p>
              <p className="policy-text">
                9.2. If you are not satisfied with Lance's Job Finder for any reason, you may request a full refund within 30 days of your initial purchase.
              </p>
              <p className="policy-text">
                9.3. To request a refund, contact our customer support team. The contact information is listed at the bottom of both our Terms of Service and Privacy Policy.
              </p>
              <p className="policy-text">
                9.4. Refunds will be processed to the original method of payment within 5-10 business days.
              </p>
              <p className="policy-text">
                9.5. This guarantee is limited to one refund per customer.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">10. Termination</h2>
              <p className="policy-text">
                10.1. We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
              </p>
              <p className="policy-text">
                10.2. Upon termination, your right to use the service will immediately cease.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">11. Limitation of Liability</h2>
              <p className="policy-text">
                11.1. In no event shall Lance's Job Finder, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">12. Disclaimer</h2>
              <p className="policy-text">
                12.1. Your use of the service is at your sole risk. The service is provided on an "AS IS" and "AS AVAILABLE" basis.
              </p>
              <p className="policy-text">
                12.2. We do not guarantee that our service will result in you obtaining employment.
              </p>
              <p className="policy-text">
                12.3. We are not responsible for the actions, content, information, or data of third parties, including potential employers contacted through our service.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">13. Governing Law</h2>
              <p className="policy-text">
                These Terms shall be governed and construed in accordance with the laws of Texas, without regard to its conflict of law provisions.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">14. Changes</h2>
              <p className="policy-text">
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any significant changes to these Terms by posting the new Terms on this page.
              </p>
            </section>

            <section className="policy-section">
              <h2 className="policy-section-title">15. Contact Us</h2>
              <p className="policy-text">
                If you have any questions about these Terms, please contact us at lance@bricca.io.
              </p>
              <p className="policy-text">
                By using Lance's Job Finder, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them.
              </p>
            </section>
          </article>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;