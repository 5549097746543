import React from 'react';
import Link from 'next/link';
import { ArrowRight, PersonStanding, MessageCircle, Heart, CreditCard } from 'lucide-react';
import { Button } from "../components/ui/button";
import { Card } from "../components/ui/card";
import DottedBackground from '../components/ui/dotted-background';

const isProd = process.env.NODE_ENV === 'production';
const signUp = isProd ? 'https://app.lancesbouncetester.com/signup' : `${process.env.REACT_APP_DEV_URL}/signin`;

const CallToActionSection = () => {
  const features = [
    { 
      icon: PersonStanding, 
      title: "Support a Solo Developer", 
      description: "Choose indie innovation over big tech corporations" 
    },
    { 
      icon: MessageCircle, 
      title: "Direct Communication", 
      description: "Get responses from the creator, not a support team" 
    },
    { 
      icon: Heart, 
      title: "Passionate Service", 
      description: "Benefit from a developer who truly cares about your success" 
    },
    { 
      icon: CreditCard, 
      title: "Fair Pricing", 
      description: "Enjoy competitive rates without corporate overhead" 
    }
  ];

  return (
    <section className="py-16 sm:py-24 bg-background relative overflow-hidden">
      <DottedBackground />
      <div className="container mx-auto relative z-10">
        <Card className="p-8 sm:p-12 shadow-lg">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="lg:w-1/2 mb-8 lg:mb-0 lg:pr-12">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-foreground leading-tight">Clean Your Email List for Less</h2>
              <p className="text-lg mb-6 text-muted-foreground">Join marketers tired of wasting money on expensive, average bounce testers. Experience the difference of personalized service.</p>
              <Button size="lg" className="w-full sm:w-auto" asChild>
                <Link href={signUp}>
                  Get started
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Link>
              </Button>
            </div>
            <div className="lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4">
              {features.map((feature, index) => (
                <div key={index} className="flex items-start space-x-4 bg-primary/5 p-4 rounded-lg transition-all duration-300 hover:bg-primary/10">
                  <feature.icon className="w-8 h-8 text-primary flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="text-lg font-semibold text-foreground">{feature.title}</h3>
                    <p className="text-sm text-muted-foreground">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default CallToActionSection;