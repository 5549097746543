import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { LogOut, Rabbit, SettingsIcon, LayoutDashboard, Zap, MoreVertical, CreditCard, Plus } from 'lucide-react';
import { cn } from "../lib/utils";
import { ScrollArea } from "../components/ui/scroll-area";
import { Button } from "../components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../components/ui/dropdown-menu";
import { useToast } from "../components/ui/use-toast";
import { Card, CardContent } from "../components/ui/card";

interface UserData {
  _id: string;
  email: string;
  active: boolean;
  createdAt: string;
  is_superadmin: boolean;
  name: string;
  profileImageUrl: string;
}

interface SidebarProps {
  onLogout: () => void;
  userId: string | null;
}

const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_API_URL;

const Sidebar: React.FC<SidebarProps> = ({ onLogout, userId }) => {
  const { toast } = useToast();
  const location = useLocation();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [creditBalance, setCreditBalance] = useState<number | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        return;
      }

      try {
        const [userResponse, creditResponse] = await Promise.all([
          fetch(`${API_URL}/api/user-settings/${userId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          }),
          fetch(`${API_URL}/api/user-credits`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          })
        ]);

        if (!userResponse.ok || !creditResponse.ok) {
          throw new Error('Failed to fetch user data or credits');
        }
        
        const userData = await userResponse.json();
        const creditData = await creditResponse.json();
        
        setUserData(userData);
        setCreditBalance(creditData.credits);
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch user data. Please try again.",
          variant: "destructive",
        });
      }
    };

    fetchUserData();
  }, [userId, toast]);

  const menuItems = [
    { to: "/dashboard", icon: LayoutDashboard, label: "Dashboard" },
    { to: "/bounce-tester", icon: Zap, label: "Bounce Tester" },
    { to: "/settings", icon: SettingsIcon, label: "Settings" },
  ];

  const userName = userData?.name || 'User';
  const userInitial = userName.charAt(0).toUpperCase();

  const abbreviateEmail = (email: string) => {
    if (!email) return '';
    const [localPart, domain] = email.split('@');
    if (localPart.length <= 8) return email;
    return `${localPart.slice(0, 6)}...@${domain}`;
  };

  return (
    <aside className="w-64 flex flex-col h-screen bg-background border-r border-gray-200">
      <div className="flex items-center h-16 border-b border-gray-200 px-4">
        <Rabbit className="h-8 w-8 text-primary flex-shrink-0" />
        <span className="ml-2 text-md font-semibold text-foreground">
          Lance's Bounce Tester
        </span>
      </div>

      <ScrollArea className="flex-grow py-4">
        <nav className="space-y-2 px-2">
          {menuItems.map((item) => (
            <Button
              key={item.to}
              asChild
              variant={location.pathname === item.to ? "secondary" : "ghost"}
              className={cn(
                "w-full justify-start h-10 px-2",
                location.pathname !== item.to && "text-muted-foreground hover:text-foreground"
              )}
            >
              <Link to={item.to} className="flex items-center">
                <item.icon className={cn(
                  "h-5 w-5 mr-3",
                  location.pathname === item.to ? "text-foreground" : "text-muted-foreground"
                )} />
                <span>{item.label}</span>
              </Link>
            </Button>
          ))}
        </nav>
      </ScrollArea>

      {/* Credit Balance Display */}
 <div className="space-y-2 px-2 py-4">
      <Card className="mt-4 mb-4">
        <CardContent className="pt-4">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium">Email Credits</span>
            <span className="text-lg font-semibold">
              {creditBalance !== null ? creditBalance.toLocaleString() : 'Loading...'}
            </span>
          </div>
          <Link to="/settings?tab=billing" className="w-full block mt-2">
            <Button variant="outline" size="sm" className="w-full">
              <Plus className="h-4 w-4 mr-1" />
              Add Credits
            </Button>
          </Link>
        </CardContent>
      </Card>
    </div>

      <div className="border-t border-gray-200 p-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              variant="ghost"
              className="w-full justify-between px-2 py-2 rounded-md"
            >
              <div className="flex items-center w-full max-w-[calc(100%-24px)]">
                <Avatar className="h-8 w-8 rounded-lg mr-3 flex-shrink-0">
                  <AvatarImage src={userData?.profileImageUrl} alt={userName} />
                  <AvatarFallback>{userInitial}</AvatarFallback>
                </Avatar>
                <div className="flex flex-col items-start min-w-0 ml-2">
                  <span className="text-sm font-medium truncate w-full text-left">{userName}</span>
                  <span className="text-xs text-muted-foreground truncate w-full text-left">
                    {abbreviateEmail(userData?.email || 'Loading...')}
                  </span>
                </div>
              </div>
              <MoreVertical className="h-5 w-5 text-muted-foreground flex-shrink-0" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent 
            align="end" 
            className="w-56"
          >
            {userData?.is_superadmin && (
              <DropdownMenuItem>
                <Link to="/super-admin">
                  Admin Panel
                </Link>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={onLogout}>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </aside>
  );
};

export default Sidebar;