import React from 'react';
import Link from 'next/link';
import { Check } from 'lucide-react';
import { Button } from "../components/ui/button";
import { Card, CardHeader, CardContent, CardFooter, CardTitle } from "../components/ui/card";
import DottedBackground from '../components/ui/dotted-background'; 

const isProd = process.env.NODE_ENV === 'production';
const signUp = isProd ? 'https://app.lancesbouncetester.com/signup' : `${process.env.REACT_APP_DEV_URL}/signin`;

const PricingSection: React.FC = () => {
  const commonFeatures = [
    "CSV File Upload",
    "Email Validation",
    "Bulk Processing",
    "Syntax, MX, disposable, & role-based checks",
    "Downloadable Results"
  ];

  const tiers = [
    {
      title: "10,000 Emails",
      price: 9,
      subheader: "For small list cleaning and personal use",
      features: commonFeatures,
      popular: false,
    },
    {
      title: "50,000 Emails",
      price: 39,
      subheader: "For growing businesses and agencies",
      features: commonFeatures,
      popular: true,
    },
    {
      title: "250,000 Emails",
      price: 99,
      subheader: "For large corporations with large lists",
      features: commonFeatures,
      popular: false,
    }
  ];

  return (
    <section className="py-16 sm:py-24 relative overflow-hidden" id="pricing">
      <DottedBackground />
      <div className="container relative z-10">
        <div className="max-w-5xl mx-auto text-center mb-16">
          <h2 className="text-4xl sm:text-5xl font-bold tracking-tight mb-4">
            No annoying monthly fees. Pay as you go.
          </h2>
          <p className="text-lg sm:text-xl text-muted-foreground">
            Choose a package that fits your needs. No hidden fees.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {tiers.map((tier, index) => (
            <Card key={index} className={`overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1 ${tier.popular ? 'border-2 border-primary relative' : ''}`}>
              {tier.popular && (
                <div className="absolute top-0 right-0 bg-primary text-primary-foreground px-4 py-1 text-xs font-semibold">
                  Most Popular
                </div>
              )}
              <CardContent className="p-6">
                <h3 className="text-2xl font-bold mb-2">{tier.title}</h3>
                <p className="text-sm text-muted-foreground mb-4">{tier.subheader}</p>
                <div className="flex items-baseline mb-6">
                  <span className="text-4xl font-bold">${tier.price}</span>
                  <span className="ml-2 text-muted-foreground">/month</span>
                </div>
                <ul className="space-y-3 mb-6">
                  {tier.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <Check className="h-5 w-5 text-primary mr-2 flex-shrink-0" />
                      <span className="text-muted-foreground text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
                <Button className="w-full" asChild>
                  <Link href={signUp}>Get started</Link>
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;