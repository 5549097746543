import React from 'react';
import { Button } from "../components/ui/button";
import DottedBackground from '../components/ui/dotted-background';

const FeaturesSection = () => {
  const features = [
    "CSV File Upload", "Email Validation", "Bulk Processing", "Invalid Email Detection",
    "Disposable Email Check", "MX Record Verification", "Syntax Validation", "Role-based Email Check",
    "Real-time Progress", "Downloadable Results", "Past Runs History", "Analytics Dashboard",
    "Email Column Selection", "Duplicate Detection", "DNS Failure Tracking", "Batch Processing",
    "Ultra-Fast Processing", "User-friendly Interface", "Detailed Reports", "High Accuracy"
  ];

  return (
    <section className="py-16 bg-background relative overflow-hidden">
      <div className="absolute inset-0 z-0">
        <DottedBackground />
      </div>
      <div className="container mx-auto text-center relative z-10">
        <h2 className="text-4xl font-bold mb-4 text-foreground">Every feature you need and more</h2>
        <h3 className="text-2xl mb-8 text-foreground">Bounce test your lists with extreme accuracy</h3>
        
        <div className="flex flex-wrap justify-center gap-2 mb-12">
          {features.map((feature, index) => (
            <Button key={index} variant="outline" className="m-1">
              {feature}
            </Button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;