import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Shadcn Imports
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Tabs, TabsList, TabsTrigger } from "../components/ui/tabs";

// Lucide Icons
import { BarChart, CheckCircle, XCircle } from 'lucide-react';

const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_API_URL;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalEmails: 0,
    validEmails: 0,
    invalidEmails: 0,
    dailyData: []
  });
  const [timeRange, setTimeRange] = useState('30d');

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/dashboard-data?range=${timeRange}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setDashboardData(data);
        } else {
          console.error('Failed to fetch dashboard data');
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, [timeRange]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const chartData = useMemo(() => {
    return dashboardData.dailyData.map(item => ({
      ...item,
      date: new Date(item.date).getTime(),
    })).sort((a, b) => a.date - b.date);
  }, [dashboardData.dailyData]);

  const SummaryCard = ({ title, value, icon, subValue, color }) => (
    <Card className="overflow-hidden transition-all duration-300 hover:shadow-md">
      <CardContent className="p-6">
        <div className={`p-2 rounded-full w-12 h-12 flex items-center justify-center ${color} mb-4`}>
          {icon}
        </div>
        <CardTitle className="text-2xl font-bold mb-1">{value.toLocaleString()}</CardTitle>
        <p className="text-sm font-medium text-muted-foreground">{title}</p>
        {subValue && <p className="text-xs text-muted-foreground mt-2">{subValue}</p>}
      </CardContent>
    </Card>
  );

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Dashboard</h1>
        <Tabs value={timeRange} onValueChange={setTimeRange} className="w-[400px]">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="7d">7 Days</TabsTrigger>
            <TabsTrigger value="30d">30 Days</TabsTrigger>
            <TabsTrigger value="90d">90 Days</TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <SummaryCard
          title="Total Emails Processed"
          value={dashboardData.totalEmails}
          icon={<BarChart className="w-6 h-6 text-primary" />}
          subValue={`+${((dashboardData.totalEmails / 100000) * 100).toFixed(2)}%`}
          color="bg-primary/10"
        />
        <SummaryCard
          title="Valid Emails"
          value={dashboardData.validEmails}
          icon={<CheckCircle className="w-6 h-6 text-green-600" />}
          subValue={`${((dashboardData.validEmails / dashboardData.totalEmails) * 100).toFixed(2)}%`}
          color="bg-green-100"
        />
        <SummaryCard
          title="Invalid Emails"
          value={dashboardData.invalidEmails}
          icon={<XCircle className="w-6 h-6 text-destructive" />}
          subValue={`${((dashboardData.invalidEmails / dashboardData.totalEmails) * 100).toFixed(2)}%`}
          color="bg-destructive/10"
        />
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Email Processing Overview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <XAxis 
                  dataKey="date"
                  tickFormatter={(timestamp) => formatDate(new Date(timestamp))}
                  type="number"
                  domain={['dataMin', 'dataMax']}
                  scale="time"
                  tickCount={7}
                />
                <YAxis 
                  allowDecimals={false}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(value) => value.toLocaleString()}
                />
                <Tooltip
                  labelFormatter={(timestamp) => formatDate(new Date(timestamp))}
                  formatter={(value, name) => [value.toLocaleString(), name]}
                  contentStyle={{
                    backgroundColor: 'hsl(var(--background))',
                    border: '1px solid hsl(var(--border))',
                    borderRadius: '6px',
                  }}
                />
                <Line 
                  type="monotone" 
                  dataKey="processed" 
                  name="Total Processed" 
                  stroke="hsl(var(--primary))" 
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 8 }}
                />
                <Line 
                  type="monotone" 
                  dataKey="valid" 
                  name="Valid" 
                  stroke="#10b981" 
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 8 }}
                />
                <Line 
                  type="monotone" 
                  dataKey="invalid" 
                  name="Invalid" 
                  stroke="hsl(var(--destructive))" 
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;